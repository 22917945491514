import { Directive, input, output } from '@angular/core';

@Directive({
  selector: '[ptLongPress]',
  standalone: true,
  host: {
    '(pointerenter)': 'onPointerEnter($event)',
    '(pointerup)': 'onCancel()',
    '(pointerleave)': 'onCancel()',
  },
})
export class LongPressDirective {
  longPressDelay = input(300);

  pointerEntered = output<boolean>();

  #timeoutId: any = null;
  #isTouch = false;

  onPointerEnter(event: PointerEvent): void {
    this.#isTouch = event.pointerType === 'touch';

    if (this.#isTouch) {
      this.#timeoutId = setTimeout(() => {
        this.pointerEntered.emit(this.#isTouch);
      }, this.longPressDelay());
    } else {
      this.pointerEntered.emit(this.#isTouch);
    }
  }

  onCancel(): void {
    if (this.#timeoutId) {
      clearTimeout(this.#timeoutId);
      this.#timeoutId = null;
    }
  }
}
