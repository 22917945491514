import { DOCUMENT } from '@angular/common';
import {
  booleanAttribute,
  DestroyRef,
  Directive,
  ElementRef,
  inject,
  input,
  OnInit,
  output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { auditTime, fromEvent, tap } from 'rxjs';

@Directive({
  standalone: true,
  selector: '[ptInfiniteScroll]',
})
export class InfiniteScrollDirective implements OnInit {
  readonly #elementRef = inject(ElementRef);
  readonly #destroyRef = inject(DestroyRef);
  readonly #document = inject(DOCUMENT);

  threshold = input<number>(200);
  hasMore = input<boolean>(true);
  isLoading = input<boolean>(false);
  useGlobalScroll = input(false, { transform: booleanAttribute });

  scrolled = output<void>();
  scrollPositionChanged = output<number>();

  ngOnInit(): void {
    const target = this.useGlobalScroll()
      ? this.#document
      : this.#elementRef.nativeElement;

    fromEvent<Event>(target, 'scroll')
      .pipe(
        takeUntilDestroyed(this.#destroyRef),
        auditTime(200),
        tap(({ target }) => {
          const { scrollingElement } = target as Document;
          this.#scroll((scrollingElement || target) as HTMLElement);
        }),
      )
      .subscribe();
  }

  #scroll(target: HTMLElement): void {
    this.scrollPositionChanged.emit(target.scrollTop);
    if (this.isLoading() || !this.hasMore()) return;

    const { scrollTop, scrollHeight, clientHeight } = target;
    const scrollPosition = scrollTop + clientHeight;

    if (scrollPosition >= scrollHeight - this.threshold()) {
      this.scrolled.emit();
    }
  }
}
